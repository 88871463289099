const MouseEvents = {

    
    contentElementClicked(event) {
        let link = this.checkLinkInPath(event);
        console.log('contentElementClicked:', link);
        //let prevent = true;
        if (link) {
          if ((link.classList && link.classList.contains('link--download')) || link.href.indexOf("mailto:") !=- 1 || link.hostname != window.location.hostname || link.target == "_blank") {
            //prevent = false;
          }
        /* if (prevent) {
            //make own object for contents
          let href = link.href.replace("http://192.168.0.14:8080/", "");
          href = href.replace("https://zanier.fixit.at/","");
          const uri = Api.BACKEND_URL + href;
          this.getContent(uri)
          event.preventDefault();
        } */
      }
    },

    checkLinkInPath(event) {
        const path = this.getEventPath(event);
        let res=null;
        path.forEach(el => {
          if (el.nodeName && el.nodeName.toLowerCase() === "a") {
            res = el;
          }
        });
        return res;
    },

    getEventPath(event) {
        let res;
        if ("composedPath" in event) {
          res = event.composedPath();
        } else if ("path" in event.path) {
          res = event.path;
        } else {
          const path = [];
          let currentElem = event.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }
          if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
            path.push(document);
          if (path.indexOf(window) === -1) path.push(window);
          res = path;
        }
        return res;
    },
        

};

export default MouseEvents;