export const Api = Object.freeze({
  BASE_URL: process.env.VUE_APP_BASE_URL,
  PUBLIC_FOLDER: process.env.VUE_APP_BASE_URL + "public",
  BACKEND_URL: process.env.VUE_APP_BACKEND,
  VIRTUAL_URL: "https://showroom.zanier.com/tour/",
  COLLECTION_WINTER_PANO: "?media-index=2",
  COLLECTION_SUMMER_PANO: "?media-index=8",
  TECHNOLOGY_PANO: "?media-index=7",
  SUSTAINABILITY_PANO: "?media-index=7",
  PARTNER_PANO: "?media-index=6",
  VISION_PANO: "?media-index=6", /* 3 is a dead end road */
  TEAM_PANO: "?media-index=4", /* 5 is a gallery */
  HISTORY_PANO: "?media-index=0",
  MYMOUNTAINPASSION_PANO: "?media-index=4",
  EN_LANGUAGE_PARAM: "language=en"
});
